import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class PreviewService {
  public src = ''
  public type = '' // VIDEO IMAGE
  public hidden = true

  constructor() {
  }

  show(src: string, type: string) {
    this.src = src
    this.type = type
    this.hidden = false
  }
}
