import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { catchError, map } from 'rxjs/operators'
import { Observable, of } from 'rxjs'
import { Copywriting } from './types/copywriting'

interface ApiError {
    message: string;
    info?: string;
    status: number;
}

interface ApiResult<T> {
    success: boolean;
    data: T;
    errors: ApiError[];
}

const APIROOT = environment.API_ROOT
const FEISHU_PROXY_API_ROOT = environment.FEISHU_PROXY_API_ROOT

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
        private http: HttpClient
  ) {
  }

  /***
     * 登录
     * @param {username: string, password: string, verifyCode: string} params
     */
  public login(params): Observable<any> {
    return this.http.post<any>(`${APIROOT}/auth/login`, params, { withCredentials: true })
      .pipe(
        catchError(err => {
          return of(err)
        })
      )
  }

  public loginByAuthing(idToken: string) {
    return this.http.post('https://www.maimemo.com/api/v2/admin/users/login/authing', { id_token: idToken })
  }

  public logout(): Observable<any> {
    return this.http.post<any>(`${APIROOT}/auth/logout`, { token: localStorage.getItem('token') })
  }

  /**
   * 获取用户登录密码，需要有 users.sk_password 权限
   * @param userId
   */
  public createSkPassword(userId: number): Observable<any> {
    return this.http.post(`https://www.maimemo.com/api/v2/admin/users/${userId}/sk_password`, {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('idToken')}`
      }
    })
  }

  public getStatisticsUsers(): Observable<any> {
    return this.http.get(`${APIROOT}/admin/statistics_users`)
  }

  /** 获取背单词公告栏 */
  public queryCopywritingsMaimemo() {
    return this.http.get<ApiResult<{ contents: Copywriting[] }>>(`${APIROOT}/contents/bulletin`)
  }

  /** 获取刻记公告栏 */
  public queryCopywritingsMarkji() {
    return this.http.get<ApiResult<{ contents: Copywriting[] }>>(`${APIROOT}/contents/bulletin/markji`)
  }

  /** * 保存公告栏 */
  public saveCopywritings(
    id,
    params: { content: string },
    options?: { project?: 'markji' }
  ): Observable<any> {
    options = options || {}
    let baseUrl = `${APIROOT}/contents/bulletin/${id}`
    if (options.project) {
      baseUrl = `${APIROOT}/contents/bulletin/${options.project}/${id}`
    }
    return this.http.post(baseUrl + '?token=' + localStorage.getItem('token'), params)
  }

  /***
     * 保存历史版本
     * @param {bulletinId: string, content: string, title: string} params
     */
  public addHistory(params): Observable<any> {
    return this.http.post(`${APIROOT}/contents/bulletin/history`, params)
  }

  public deleteHistory(id): Observable<any> {
    return this.http.delete(`${APIROOT}/contents/bulletin/history/` + id)
  }

  public queryHelps() {
    return this.http.get(`${APIROOT}/issues/help`)
  }

  /***
     * 保存一条帮助与反馈
     * @param {title: string, description: string, type: string, link: string} params
     */
  public addHelp(params) {
    return this.http.post(`${APIROOT}/issues/help`, params)
  }

  public deleteHelp(id) {
    return this.http.delete(`${APIROOT}/issues/help/` + id)
  }

  /***
     * 修改一条帮助与反馈
     * @param {title: string, description: string, type: string, link: string, status: string} params
     */
  public updateHelp(id, params) {
    return this.http.post(`${APIROOT}/issues/help/` + id, params)
  }

  public getTop20(): Observable<any> {
    return this.http.get(`${APIROOT}/issues/hot_keywords`)
  }

  /***
     * 为一条帮助反馈调整位置
     * @param {title: string, description: string, type: string, link: string, status: string} params
     */
  public moveHelp(id, params) {
    return this.http.post(`${APIROOT}/issues/help/${id}/order`, params)
  }

  /***
     * 问题总汇列表
     * @param {limit: number, offset: number} params
     */
  public getIssues(params): Observable<any> {
    return this.http.get(`${APIROOT}/issues/paged_v2`, {
      params
    })
  }

  /***
     * 根据关键字获取问题总汇列表
     * @param {keyword: string} params
     */
  public getIssuesByKeyWord(params): Observable<any> {
    return this.http.get(`${APIROOT}/issues`, {
      params: params
    })
  }

  public getIssuesByTags(tags: string[]): Observable<any> {
    return this.http.get(`${APIROOT}/issues/query`, {
      params: {
        tags: tags.join(',')
      }
    })
  }

  /***
     * 增加搜索次数
     * @param {keyword: string} params
     */
  public addKeywordSearchedCount(params): Observable<any> {
    return this.http.post(`${APIROOT}/issues/search/add_count`, params)
  }

  /***
     * 改变问题权重
     * @param id
     * @param params {isUp: boolean, keyword: string}
     */
  public changeIssueWeight(id, params): Observable<any> {
    return this.http.post(`${APIROOT}/issues/` + id + '/weight', params)
  }

  public pinIssue(id: string): Observable<any> {
    return this.http.post(`${APIROOT}/issues/${id}/pin`, {})
  }

  public unpinIssue(id: string): Observable<any> {
    return this.http.post(`${APIROOT}/issues/${id}/unpin`, {})
  }

  public getPinnedIssues(): Observable<any> {
    return this.http.get(`${APIROOT}/issues/pinned`, {})
  }

  /***
     * 新建一个问题
     * @param params {
     * title: string,
     * answers: {
     *    solution: string
     * },
     * keywords: string,
     * project: string,
     * type: string}
     */
  public newIssue(params): Observable<any> {
    return this.http.post(`${APIROOT}/issues`, params)
  }

  public editIssue(id, params): Observable<any> {
    return this.http.put(`${APIROOT}/issues/` + id, params)
  }

  public deleteIssue(id): Observable<any> {
    return this.http.delete(`${APIROOT}/issues/` + id)
  }

  /***
     * 新建一个收藏问题
     * @param {alias: string, keyword: strig} params
     */
  public newCollection(params): Observable<any> {
    return this.http.put(`${APIROOT}/issues/favorite/keywords`, params)
  }

  public getCollections(): Observable<any> {
    return this.http.get(`${APIROOT}/issues/favorite/keywords`)
  }

  /***
     * 保存收藏的问题
     * @param {string} content tips: 收藏内容
     */
  public setCollections(content): Observable<any> {
    return this.http.post(`${APIROOT}/issues/favorite/keywords`, { content: content })
  }

  public searchRoom(keyword, offset, limit, searchType): Observable<any> {
    return this.http.get(`${APIROOT}/chat/search`, {
      params: { keyword: keyword, limit: limit, offset: offset, type: searchType }
    })
  }

  public getRoomList(limit?: number, offset?: number, sort?: string, type?: string, status?: string): Observable<any> {
    return this.http.get(`${APIROOT}/chat/room`, {
      params: {
        limit: String(limit),
        offset: String(offset),
        sort,
        type,
        status
      }
    })
  }

  public getRoom(roomId): Observable<any> {
    return this.http.get(`${APIROOT}/chat/room/${roomId}`)
  }

  public enterRoom(roomId: string): Observable<any> {
    return this.http.post(`${APIROOT}/metrics/room_session/start`, {
      roomId: roomId
    })
  }

  public existRoom(roomId: string): Observable<any> {
    return this.http.post(`${APIROOT}/metrics/room_session/finish`, {
      roomId: roomId
    })
  }

  public getUserInfo(userId, userType): Observable<any> {
    return this.http.get(`${APIROOT}/users/${userId}?token=${localStorage.getItem('token')}&userType=${userType}`)
  }

  /***
     * 获取消息列表
     * @param {roomId: string, lastMessageId: string} params
     */
  public getMessageList(params): Observable<any> {
    return this.http.get(`${APIROOT}/chat/message`, {
      params: params
    })
  }

  public sendMessage(params): Observable<any> {
    return this.http.post(`${APIROOT}/chat/message`, params)
  }

  public markSolved(roomId): Observable<any> {
    return this.http.post(`${APIROOT}/chat/room/` + roomId + '/mark_solved', {})
  }

  public markUnSolved(roomId): Observable<any> {
    return this.http.post(`${APIROOT}/chat/room/` + roomId + '/mark_unsolved', {})
  }

  public markClosed(roomId, force?): Observable<any> {
    return this.http.post(`${APIROOT}/chat/room/` + roomId + '/mark_close', {
      force: !!force
    })
  }

  public createSignFix(date, userId): Observable<any> {
    return this.http.post(`${APIROOT}/users/sign_fix`, {
      date,
      userId
    })
  }

  // 获取未登录数量
  public countUnsolved(): Observable<any> {
    return this.http.get(`${APIROOT}/chat/room/count_unsolved`)
  }

  // 上传照片
  public sendImage(params): Observable<any> {
    return this.http.post(`${APIROOT}/upload/image`, params)
  }

  // 上传视频
  public sendVideo(videoFormData): Observable<any> {
    const headers = new HttpHeaders()
    headers.append('Content-Type', 'multipart/form-data')
    const options = {
      headers
    }
    return this.http.post(`${APIROOT}/upload/video`, videoFormData, options)
  }

  // 获取客服在线情况
  public getOnline(roomId?): Observable<any> {
    let param = `token=${localStorage.getItem('token')}`
    if (roomId) {
      param += `&roomId=${roomId}`
    }

    return this.http.get(`${APIROOT}/chat/room/enter?${param}`)
  }

  // 离线（客服更换房间，向服务器更新状态，删除上一个房间的在线状态）
  public deleteOnline(roomId): Observable<any> {
    let param = `token=${localStorage.getItem('token')}`
    if (roomId) {
      param += `&roomId=${roomId}`
    }

    return this.http.delete(`${APIROOT}/chat/room/leave?${param}`)
  }

  public uploadImage(param) {
    return this.http.post(`${APIROOT}/upload/image/cke`, param)
  }

  public getChatTags(param) {
    return this.http.get(`${APIROOT}/chat/tags`, {
      params: param
    })
  }

  public getNotification(): Observable<any> {
    return this.http.get(`${APIROOT}/chat/notification`)
  }

  public saveNotification(param): Observable<any> {
    return this.http.post(`${APIROOT}/chat/notification`, param)
  }

  public updateNotification(id, param): Observable<any> {
    return this.http.post(`${APIROOT}/chat/notification/${id}`, param)
  }

  public deleteNotification(id): Observable<any> {
    return this.http.delete(`${APIROOT}/chat/notification/${id}`)
  }

  public orderNotification(id, param): Observable<any> {
    return this.http.post(`${APIROOT}/chat/notification/${id}/order`, param)
  }

  public getAllTreeTags(): Observable<any> {
    return this.http.get(`${APIROOT}/chat/tags_v2/${environment.tagV2RootId}/recursive`)
  }

  public createTag(parentId, data): Observable<any> {
    return this.http.post(`${APIROOT}/chat/tags_v2/${parentId}/child`, data)
  }

  public renameTag(id, name): Observable<any> {
    return this.http.patch(`${APIROOT}/chat/tags_v2/${id}`, {
      name
    })
  }

  public deleteTag(id): Observable<any> {
    return this.http.delete(`${APIROOT}/chat/tags_v2/${id}`)
  }

  public reorderTag(id, afterId): Observable<any> {
    return this.http.post(`${APIROOT}/chat/tags_v2/${id}/placeAfter`, {
      after: afterId
    })
  }

  public getRewardRecords(userId): Observable<any> {
    return this.http.get(`${APIROOT}/users/reward_record/${userId}`)
  }

  public getABTestingGroup(userId): Observable<any> {
    return this.http.get(`${APIROOT}/users/${userId}/ab_group`)
  }

  /***
     * 奖励单词上限
     * @param {uid: string, voc: number, comment: string} params
     */
  public reward(params): Observable<any> {
    return this.http.post(`${APIROOT}/users/reward`, params)
  }

  public addFollowRoom(params): Observable<any> {
    return this.http.post(`${APIROOT}/chat/room/follow`, params)
  }

  public removeFollowRoom(roomId): Observable<any> {
    return this.http.delete(`${APIROOT}/chat/room/follow/${roomId}`)
  }

  public quitRoom(roomId): Observable<any> {
    return this.http.get(`${APIROOT}/chat/room/${roomId}/quit`)
  }

  public getRestrictedTime(userId): Observable<any> {
    return this.http.get(`${APIROOT}/chat/restriction/${userId}`)
  }

  public restrictUser(userId, duration, reason): Observable<any> {
    if (duration === 0) {
      return this.http.delete(`${APIROOT}/chat/restriction/${userId}`)
    } else {
      return this.http.put(`${APIROOT}/chat/restriction/${userId}`, {
        duration,
        reason
      })
    }
  }

  public createRoomTagV2(roomId, tagId, attributes): Observable<any> {
    return this.http.post(`${APIROOT}/chat/room/${roomId}/tags_v2`, {
      ...attributes,
      tagId
    })
  }

  public editRoomTagV2(roomId, entryId, attributes): Observable<any> {
    return this.http.put(`${APIROOT}/chat/room/${roomId}/tags_v2/${entryId}`, attributes)
  }

  public deleteRoomTagV2(roomId, entryId): Observable<any> {
    return this.http.delete(`${APIROOT}/chat/room/${roomId}/tags_v2/${entryId}`)
  }

  public createTicketTagV2(ticketId, tagId, attributes): Observable<any> {
    return this.http.post(`${APIROOT}/chat/ticket/${ticketId}/tags_v2`, {
      ...attributes,
      tagId
    })
  }

  public editTicketTagV2(ticketId, entryId, attributes): Observable<any> {
    return this.http.put(`${APIROOT}/chat/ticket/${ticketId}/tags_v2/${entryId}`, attributes)
  }

  public deleteTicketTagV2(ticketId, entryId): Observable<any> {
    return this.http.delete(`${APIROOT}/chat/ticket/${ticketId}/tags_v2/${entryId}`)
  }

  public getStatistics(fromTime: string, toTime: string, isGlobal: boolean): Observable<any> {
    const uriQuery = []
    if (fromTime) uriQuery.push('fromTime=' + encodeURIComponent(fromTime))
    if (toTime) uriQuery.push('toTime=' + encodeURIComponent(toTime))
    if (isGlobal) uriQuery.push('isGlobal=true')
    let uriQueryString = uriQuery.join('&')
    if (uriQueryString) uriQueryString = `?${uriQueryString}`
    return this.http.get(`${APIROOT}/chat/statistics${uriQueryString}`)
  }

  public queryNewStatistics(body): Observable<any> {
    return this.http.post(`${APIROOT}/metrics/query`, body)
  }

  public queryManyNewStatistics(body): Observable<any> {
    return this.http.post(`${APIROOT}/metrics/query/many`, body)
  }

  public lookupRoom(params): Observable<any> {
    return this.http.post(`${APIROOT}/lookup/room`, params)
  }

  public lookupTicket(params): Observable<any> {
    return this.http.post(`${APIROOT}/lookup/ticket`, params)
  }

  public lookupHeat(params): Observable<any> {
    return this.http.post(`${APIROOT}/lookup/heat`, params)
  }

  public lookupHistogram(params): Observable<any> {
    return this.http.post(`${APIROOT}/lookup/histogram`, params)
  }

  createFeishuFeedback(body) {
    return this.http.post<any>(`${FEISHU_PROXY_API_ROOT}/feishu/project/proxy`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('idToken')}`
      }
    })
  }

  evaluateAbTestCode(
    code: string,
    body: {
        user_id?: number
        user_agent?: string
        user_ip?: string
      }
  ) {
    return this.http.post(`https://www.maimemo.com/api/v1/admin/abtest/rules/${code.toUpperCase()}/evaluates`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('idToken')}`
      }
    }).pipe(map((resp: any) => resp.data))
  }
}
