import { RouterModule, Routes } from '@angular/router'
import { NgModule } from '@angular/core'
import { LoginComponent } from './modules/login/login.component'
import { GuardService } from './services/guard.service'

const routes: Routes = [
  {
    path: '',
    redirectTo: '/newadmin/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'newadmin/login',
    component: LoginComponent
  },
  {
    path: 'newadmin',
    loadChildren: () => import('./layout/layout.module').then(x => x.LayoutModule),
    canActivate: [GuardService]
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
