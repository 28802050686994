import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { PreviewService } from './preview.service'

@Component({
  selector: 'ngx-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent {
    @ViewChild('videoElement') videoElement: ElementRef<HTMLVideoElement>

    constructor(
        public preview: PreviewService
    ) {
    }

    hide() {
      this.preview.hidden = true
      this.preview.src = ''
      if (this.videoElement) {
        this.videoElement.nativeElement.load()
      }
    }
}
