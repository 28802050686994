import { ApiService } from './api.service'
import { PERMISSION_CHECK } from '../config/app'
import { Injectable } from '@angular/core'
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router'
import { Observable } from 'rxjs'
import { PrivilegesService } from './privilegesService'

@Injectable()
export class GuardService implements CanActivate, CanActivateChild {
  permit = true

  constructor(
    private permitService: PrivilegesService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService) {
  }

  canActivate() {
    return true
  }

  checking(state) {
    const time = Number(localStorage.getItem('_start_'))
    if (localStorage.getItem('token') && Math.floor(Date.now()) < time) {
      return true
    }
    this.router.navigate(['/newadmin/login'], { queryParams: {
      redirectUrl: location.href.replace(/^.*?\/\/.*?\//, '')
    } })
    return false
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    let { token, expired_time } = route.queryParams
    console.info(`route.queryParams token: ${token} expired_time: ${expired_time}`)
    expired_time = String(expired_time).length === 10 ? expired_time * 1000 : expired_time
    if (token && expired_time) {
      localStorage.setItem('token', token)
      localStorage.setItem('_start_', expired_time)
    }
    console.info(`permit check switch is ${PERMISSION_CHECK}`)
    return this.checking(state)
  }
}
