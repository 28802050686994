import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ApiService } from '../../services/api.service'

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(
    private http: HttpClient,
    private api: ApiService
  ) { }

  login(body) {
    return this.api.login(body)
  }

  loginByAuthing(token) {
    return this.api.loginByAuthing(token)
  }

  setLogin(res, idToken: string) {
    localStorage.setItem('idToken', idToken)
    localStorage.setItem('token', res.data.token)
    localStorage.setItem('userId', res.data.user.id)
    localStorage.setItem('_start_', String(Math.floor(Date.now()) + (7 * 24 * 60 * 60 * 1000)))
  }
}
