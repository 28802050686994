import { Injectable } from '@angular/core'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { environment } from '../../environments/environment'
import { catchError, map, tap } from 'rxjs/operators'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzModalService } from 'ng-zorro-antd/modal'
import { Router } from '@angular/router'
import { Token } from './token.class'
import * as HttpStatus from 'http-status-codes'

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private token: any
  private _once: boolean = true

  constructor(
        private notification: NzNotificationService,
        private modalService: NzModalService,
        private router: Router
  ) {}

  isOffline = false
  offlineMessage = ''

  map(res) {
    if (res instanceof HttpResponse) {
      res = res.clone({ body: res.body })
    }
    return res
  }

  public handleError(error) {
    if (error.status === 0) {
      if (!this.isOffline) {
        this.isOffline = true
        const nt = this.notification.error('网络异常', '请检查你的网络',
          { nzDuration: 0, nzKey: 'offline' }
        )
        nt.onClose.asObservable().subscribe(() => {
          this.isOffline = false
        })
        this.offlineMessage = nt.messageId
      }
    }
    if ((error.status === 403 || error.status === 401) && this._once) {
      let errorStatus = { message: '权限拒绝' }
      if (error.error && error.error.errors && Array.isArray(error.error.errors)) {
        errorStatus = error.error.errors[0]
      }
      this._once = false
      const NzModalRef = this.modalService.confirm({
        nzTitle: '401',
        nzContent: errorStatus.message,
        nzOkText: '留在当前页面',
        nzCancelText: '跳转到登录切换用户',
        nzOnOk: () => console.info('留在当前页面'),
        nzOnCancel: () => this.router.navigate(['newadmin/login'])
      })
      NzModalRef.afterOpen.subscribe(() => this._once = false)
      NzModalRef.afterClose.subscribe(() => this._once = true)
    }
    if (error.status >= 500) {
      if (error.error.errors && error.error.errors.length) {
        this.notification.error(`${error.status} ${HttpStatus.getStatusText(error.status)}`, `${error.error.errors[0].message}。错误代码：${error.error.errors[0].code}`)
      } else {
        this.notification.error(`${error.status} ${HttpStatus.getStatusText(error.status)}`, '出错了，别担心，这不是你的错。')
      }
    }
    if (error.error && error.error.errors && error.status !== 401 && error.status !== 403 && error.status < 500) {
      error.error.errors.forEach(val => {
        this.notification.warning('无效的请求', val.message)
      })
    }
    return throwError(error)
  }

  // 除了login之外都要加token，如果错误就获取error
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith(environment.API_ROOT) && !(req.url.endsWith('login'))) {
      req = req.clone({
        setHeaders: { Token: Token.get() || '' }
      })
    }
    if (req.url.includes('/api/v2')) {
      req = req.clone({
        setParams: { token: Token.get() || '' },
        setHeaders: { Authorization: `Bearer ${localStorage.getItem('idToken')}` }
      })
    }
    return next.handle(req).pipe(
      map(res => this.map(res)),
      tap((res) => {
        if (res instanceof HttpResponse) {
          this.isOffline = false
          this.notification.remove(this.offlineMessage)
        }
      }),
      catchError(error => this.handleError(error))
    )
  }
}
