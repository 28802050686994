export interface MenuItem {
    title?: string;
    comment?: string;
    visible?: Boolean;
    submenu?: MenuItem[];
    path?: string;
    messageCountTag?: string;
}
export const PREFIX = '/newadmin'
export const ACTIONS = ['CREATE', 'READ', 'UPDATE', 'DELETE']
export const PERMISSION_CHECK = false
export const Menu: MenuItem[] = [
  {
    title: '首页',
    comment: '只作映射用',
    visible: true,
    submenu: [{
      path: `${PREFIX}/dashboard`,
      title: '欢迎'
    }]
  },
  {
    title: '问题总汇',
    comment: '只作映射用',
    visible: true,
    path: `${PREFIX}/operation/problem`
  },
  {
    title: '运营管理',
    comment: '只作映射用',
    visible: true,
    submenu: [
      {
        title: '文案管理',
        visible: true,
        path: `${PREFIX}/operation/copywriting`
      }, {
        title: '帮助与反馈',
        visible: true,
        path: `${PREFIX}/operation/help`
      }
    ]
  },
  {
    title: '人工客服',
    comment: '只作映射用',
    visible: true,
    submenu: [{
      title: '我关注的',
      visible: true,
      path: `${PREFIX}/customerService/follow`,
      messageCountTag: ''
    }, {
      title: '新用户（14 天）',
      visible: true,
      path: `${PREFIX}/customerService/new_user-unsolved`,
      messageCountTag: 'newUser'
    }, {
      title: '全部 未回复',
      visible: true,
      path: `${PREFIX}/customerService/ticket-unsolved`,
      messageCountTag: 'unsolved'
    }, {
      title: 'iOS 未回复',
      visible: true,
      path: `${PREFIX}/customerService/ios-unsolved`,
      messageCountTag: 'ios'
    }, {
      title: '安卓 未回复',
      visible: true,
      path: `${PREFIX}/customerService/android-unsolved`,
      messageCountTag: 'android'
    }, {
      title: '全部 未关闭',
      visible: true,
      path: `${PREFIX}/customerService/ticket-solved`,
      messageCountTag: 'allSolved'
    }, {
      title: 'iOS 未关闭',
      visible: true,
      path: `${PREFIX}/customerService/ios-solved`,
      messageCountTag: 'iosSolved'
    }, {
      title: '安卓 未关闭',
      visible: true,
      path: `${PREFIX}/customerService/android-solved`,
      messageCountTag: 'androidSolved'
    }, {
      title: '我参与的',
      visible: true,
      path: `${PREFIX}/customerService/join`,
      messageCountTag: ''
    }, {
      title: '全部房间',
      visible: true,
      path: `${PREFIX}/customerService/all`,
      messageCountTag: ''
    }, {
      title: '高级搜索',
      visible: true,
      path: `${PREFIX}/lookup`
    }, {
      title: '消息设置',
      visible: true,
      path: `${PREFIX}/messageSetting`
    }]
  }
]
export const USER_RECORD = {
  sign: '签到记录',
  backup: '备份记录',
  access: '访问记录',
  order: '订单记录',
  reward: '奖励记录',
  reset: '重置记录',
  log: '系统日志',
  study_record: '学习记录'
}
export const USERINFO_ZERO = {
  closedTime: '注销时间'
}
export const USERINFO_ONE = {
  email: '用户邮箱',
  phone: '用户手机',
  level: '用户等级'
}

export const USERINFO_FOUR = {
  deviceInfo: '设备版本',
  market: '销售型号',
  appVersion: '应用版本'
}

export const USERINFO_FIVE = {
  signDeviceInfo: '签到设备',
  signMarket: '签到型号',
  signAppVersion: '签到版本'
}

export const USERINFO_TWO = {
  registerTime: '注册时间'
}
export const USERINFO_THREE = {
  replenishCard: '补签卡',
  maxVocCount: '单词上限',
  history: '历史回顾',
  totalPaymentVoc: '购买上限',
  learnedVocCount: '已学上限',
  maxSignDays: '最大连续',
  continuousSignDays: '连续签到',
  totalSignDays: '累计签到'
}
export const REMARK_REWARD = {
  ios: '苹果 3.8.0 好评',
  android: '安卓 3.8.0 好评'
}
