<nz-modal [nzVisible]="true" [nzMask]="true" [nzTitle]="null" [nzFooter]="null" [nzClosable]="false">
    <div *nzModalContent>
        <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()">
            <nz-form-item style="margin-bottom: 24px;">
                <nz-form-control nzHasFeedback
                                 nzErrorTip='请输入用户名'>
                    <nz-input-group nzPrefixIcon="user">
                        <input type="text" nz-input formControlName="username" placeholder="用户名">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item style="margin: 24px 0;">
                <nz-form-control nzHasFeedback
                                 nzErrorTip='请输入密码'>
                    <nz-input-group [nzPrefixIcon]="'lock'">
                        <input type="password" nz-input formControlName="password" placeholder="密码">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item style="margin: 24px 0;">
                <nz-form-label [nzSpan]="8">
                    <img nz-tooltip nzTooltipTitle="点击刷新验证码" [src]="verifycode" style="width: 112px;" (click)="reloadVerifyCode()"/>
                    <i nz-tooltip
                       nzTooltipTitle="点击刷新验证码"
                       nz-icon
                       nzType="reload"
                       nzTheme="outline"
                       style="padding: 0 10px;"
                       (click)="reloadVerifyCode()">
                    </i>
                </nz-form-label>
                <nz-form-control [nzSpan]="16" nzHasFeedback nzErrorTip="请输入验证码">
                    <input nz-input placeholder="验证码" formControlName="verifyCode"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <!-- <a class="login-form-forgot" class="login-form-forgot">Forgot password</a> -->
                <!-- <label nz-checkbox formControlName="remember">
                          <span>Remember me</span>
                  </label> -->
                <button nz-button class="login-form-button" [nzType]="'primary'">Log in</button>
            </nz-form-item>
        </form>
        <button nz-button (click)="loginByAuthing()">SSO Login</button>
    </div>

</nz-modal>

<div class="record">
    <p class="copyright">©
        清远墨墨教育科技有限公司 版权所有，<a href="https://beian.miit.gov.cn" style="color: inherit !important;">粤ICP备18119697号</a>
    </p>

    <a
        target="_blank"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44180202000639"
        class="record-color"
        style="display: inline-block; text-decoration: none; height: 20px; line-height: 20px;"
    >
        <img src="assets/images/备案图标.png" style="float: left;"/>
        <p style="float: left; margin: 0 0 0 5px;">
            粤公网安备 44180202000639号
        </p>
    </a>
</div>
