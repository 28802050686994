import { Component, OnInit } from '@angular/core'
import { UserService } from './user.service'
import { ActivatedRoute, Router } from '@angular/router'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { environment } from '../../../environments/environment'
import { Authing } from '@authing/browser'

const AUTHING_APP_ID = '62257f154c92c4183c730401'
const AUTHING_APP_HOST = 'https://maimemo-admin.authing.cn'

@Component({
  templateUrl: './login.component.html',
  styles: [`
    .login-form-forgot {
      float: right;
    }
    .login-form-button {
      width: 100%;
    }

    /* 备案 */
    .record {
        width: 300px;
        margin: 0 auto;
        padding: 20px 0;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 100000;
        font-size: 12px;
        text-align: center;
        color: #939393;
    }
    .record-color {
        color: #939393
    }
  `
  ]
})
export class LoginComponent implements OnInit {
  validateForm: FormGroup
  isVisible = true
  verifycode = null
  subscription = null

  authing = new Authing({
    appId: AUTHING_APP_ID,
    domain: AUTHING_APP_HOST,
    redirectUri: location.href,
    scope: 'openid profile extended_fields',
    useImplicitMode: true,
    implicitResponseType: 'id_token token',
  })

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private fb: FormBuilder,
      private loginService: UserService
  ) {
    if (this.authing.isRedirectCallback()) {
      const redirectUrl = this.route.snapshot.queryParams.redirectUrl

      this.authing.handleRedirectCallback().then(async token => {
        this.loginService.loginByAuthing(token.idToken).toPromise().then(res => {
          this.loginService.setLogin(res, token.idToken)
          if (redirectUrl) {
            this.router.navigateByUrl(redirectUrl)
          } else {
            this.router.navigate(['/newadmin/dashboard'])
          }
        })
      })
    }
  }

  submit() {
    this.loginService.login(this.validateForm.value).subscribe((res: any) => {
      if (res.error) {
        this.validateForm.patchValue({ verifyCode: '' })
        this.reloadVerifyCode()
        return false
      }
      // this.loginService.setLogin(res)
      this.router.navigate(['/newadmin/dashboard'])
    })
  }

  loginByAuthing() {
    this.authing.loginWithRedirect().then()
  }

  reloadVerifyCode() {
    this.verifycode = `${environment.API_ROOT}/auth/verifyCode?t=${Math.random()}`
  }

  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty()
      this.validateForm.controls[i].updateValueAndValidity()
    }
    if (this.validateForm.pending) {
      this.subscription = this.validateForm.statusChanges.subscribe(status => {
        if (status === 'VALID') {
          this.submit()
        }
      })
    } else {
      if (this.validateForm.valid) {
        this.submit()
      }
    }
  }

  ngOnInit(): void {
    this.reloadVerifyCode()
    this.validateForm = this.fb.group({
      username: [null, [Validators.required]],
      password: [null, [Validators.required]],
      verifyCode: [null, [Validators.required]]
    })
  }
}
