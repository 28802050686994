import { Router } from '@angular/router'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { PERMISSION_CHECK, PREFIX } from '../config/app'
import { Injectable } from '@angular/core'

@Injectable()
export class PrivilegesService {
  constructor(private notify: NzNotificationService, private router: Router) { }

  default = [{ frontend_path: '/newadmin/dashboard', action: ['READ'] }]

  addPrefix(privileges) {
    return privileges.filter(val => val.frontend_path).map(val => {
      val.frontend_path = PREFIX + val.frontend_path
      return val
    })
  }

  addDefault(privileges) {
    if (!Array.isArray(privileges)) {
      throw new Error('params require array')
    }
    return privileges.concat(this.default)
  }

  isAllow(url, action) {
    let result = false
    if (!PERMISSION_CHECK) {
      result = true
    }
    result = JSON.parse(localStorage.getItem('privileges'))
      .filter(val => val.action.includes(action))
      .some(val => val.frontend_path === url)
    console.info(`privilege service check result ${result}`)
    return result
  }

  checkNotify(action) {
    const isAllow = this.isAllow(this.router.url, action)
    if (!isAllow) {
      this.notify.warning('警告', '权限拒绝')
    }
    return isAllow
  }
}
